.text-overlayx {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  text-align: center;

}

.text-overlayx h1 {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 16px;
}

.text-overlayx p {
  font-size: 15px;
  margin-left: 22%;
  text-align: justify;
}

.split-container {
  display: flex;
  flex-direction: row;
}

.split-item {
  flex: 1;
  padding: 10px;
  border: 0.5px solid #ccc;
}

.ul1 {
  list-style-type: disc;
  margin-left: 3%;
  font-size: 18px;
}

li {
  margin-bottom: 2px;
  font-weight: bold;
}

.header {
  margin-bottom: 35px;
}

.newFullPageImage img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}


@media only screen and (min-width: 1025px) and (max-width: 1919px) {
  .newTextOverLay {
    position: absolute;
    top: 10%;
    left: 10%;
  }

  .newTextOverLay h1 {
    font-size: 60px;
    font-weight: bold;
    color: white;
  }

  .newTextOverLay p {
    font-size: 16px;
    color: white;
    padding-right: 4%;
  }
}

@media only screen and (min-width: 418px) and (max-width: 1024px) {
  .newTextOverLay {
    position: absolute;
    top: 10%;
    left: 10%;
  }

  .newTextOverLay h1 {
    font-size: 40px;
    font-weight: bold;
    color: white;
  }

  .newTextOverLay p {
    font-size: 16px;
    color: white;
    padding-right: 4%;
  }
}

@media only screen and (max-width: 480px) {
  .newTextOverLay {
    position: absolute;
    top: 10%;
    left: 10%;
  }

  .newTextOverLay h1 {
    font-size: 20px;
    font-weight: bold;
    color: white;
  }

  .newTextOverLay p {
    font-size: 10px;
    color: white;
    padding-right: 4%;
  }
}