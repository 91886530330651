  .image-container {
    position: relative;
  }
  .full-page-image5 img {
    max-width: 80%;
    max-height: 100%;
    object-fit: cover;
    display: block;
  }
  .text-overlay2{
    font-size: 19px;
    margin-top: 25px;
    text-align: justify;
    color:dimgrey;
  }
  .header6{
    margin-top: 5%;
    color:black;
    font-size:40px;
  }
  .full-screen-line {
    top: 100%;
    left: 0;
    right: 0;
    border-top: 5px solid black;
    margin-bottom:0.5%;
  }
  .header8{
    margin-top: 2%;
    color:cadetblue;
    font-size:30px;
    animation: slideIn 1s ease-in-out;
  }
  @keyframes slideIn {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  