.split-container {
    
    display: flex;
    flex-direction: row;
  }
  
  .split-item1{
    flex-basis: 70%;
    flex: 1;
    padding: 2px;
  }
  .header9{
    margin-top: 15px;
    color:cadetblue;
    font-size:15px;
    margin-left:20px;
    animation: slideIn 1s ease-in-out;
  }
  .lastpara{
    font-size: 18px !important;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  }