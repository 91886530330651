img{
    max-width: 200px;
    max-height: 200px;

}
.back{
    width:64px;
    height:32px;
    background-color: #a100ff;
    position: absolute;
    top:-5px;
    right:-8px;
    z-index:-1;
}
.head1{
    font-family: Graphik,Arial,system-ui,-apple-system,blinkmacsystemfont,Segoe UI,roboto,ubuntu,Helvetica Neue,oxygen,cantarell,sans-serif;
    font-weight: 800;
    font-style: normal;
    text-transform: none;
    color: #000;
    line-height: 1.05;
    font-size: 2.5rem;
}
.head3{
    font-family: Graphik,Arial,system-ui,-apple-system,blinkmacsystemfont,Segoe UI,roboto,ubuntu,Helvetica Neue,oxygen,cantarell,sans-serif;
    font-weight: 600;
    font-style: normal;
    text-transform: none;
    color: #000;
    line-height: 1.05;
    font-size: 2rem;
}
.cloudhead{
    font-family: Arial, Helvetica, sans-serif !important;
}

.newParagraph{
    font-size: 1rem;
}