.full-page-image {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .full-image img{
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  
  .full-page-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .image-container {
    position: relative;
  }
  
  .text-overlay {
    position: absolute;
    top: 31%;
    left: 31%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }
  
  .text-overlay h1 {
    font-size: 79px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .text-overlay p {
    font-size: 19px;
    margin-left: 10%;
    text-align: justify;
  }

  .bg-light h2{
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 16px;
    margin-left: 4%;
  }
  .bg-light p{
    font-size: 19px;
    margin-left: 1%;
    margin-bottom: 56px;
    text-align: justify;
  }
  .split-container {
    display: flex;
    flex-direction: row;
  }
  
  .split-item {
    flex: 1;
    padding: 10px;
    border: 0.5px solid #ccc;
  }
  ul {
    list-style-type: disc;
    margin-left: 20px;
    font-size: 13px;
  }
  
  li {
    margin-bottom: 2px;
    font-weight: bold;
  }
  .header{
    margin-bottom: 35px;
  }
