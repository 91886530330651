.full-page-image1 {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .full-image img{
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  
  .full-page-image1 img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .image-container {
    position: relative;
  }
  
  .text-overlay1 {
    position: absolute;
    top: 31%;
    left: 31%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }
  
  .text-overlay1 h1 {
    font-size: 79px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .text-overlay1 p {
    font-size: 19px;
    margin-left: 20%;
    text-align: justify;
  }

  .bg-light h2{
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 16px;
    margin-left: 4%;
  }
  .bg-light p{
    font-size: 19px;
    margin-left: 1%;
    margin-bottom: 56px;
    text-align: justify;
  }
  .split-container {
    display: flex;
    flex-direction: row;
  }
  
  .split-item {
    flex: 1;
    padding: 10px;
    border: 0.5px solid #ccc;
  }
  ul {
    list-style-type: disc;
    margin-left: 20px;
    font-size: 13px;
  }
  
  li {
    margin-bottom: 2px;
    font-weight: bold;
  }
  .header{
    margin-bottom: 35px;
  }
  .full-screen-line1 {
    top: 100%;
    left: 0;
    right: 0;
    border-top: 2px solid rgb(75, 71, 71);
    margin-bottom:0.5%;
  }