.full-page-image3 {
  display: flex;
  justify-content: center;
  align-items: center;

}

.full-page-image3 img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.image-container {
  position: relative;
}

.section {
  margin-top: 2%;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  font-size: 3px;
}

.column {
  background-color: #ebebeb;
  padding: 2%;
  border: 5px solid #ccc;
}

.ph {
  font-size: 30px !important;
  font-style: italic;
  font-weight: normal !important;
}

.para {
  font-size: 16px !important;
}

.full-page-image4 img {
  max-width: 50%;
  max-height: 50%;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}